import { DefaultProfileSrc } from "assets/images"
import { FC } from "react"

interface props {
    size: number
    src?: string
    className?: string
}

const ProfileImage: FC<props> = ({ size, src, className }) => {
  return (
    <div
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
      className={["rounded-full", className].asClass}
    >
      <img alt="Profile" className="rounded-full object-cover w-full h-full" src={src || DefaultProfileSrc} />
    </div>
  )
}

export default ProfileImage
