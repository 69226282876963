import { PaperClipIcon, SearchIcon, SendIcon, XIcon } from "assets/icons"
import Loader from "components/loaders/Loader"
import firebase from "config/firebase"
import useAuthContext from "contexts/Auth/useAuthContext"
import useChat from "contexts/Chat/useChat"
import useChats from "contexts/Chat/useChats"
import useUser from "contexts/User/useUser"
import { getDownloadURL, ref } from "firebase/storage"
import useAwaitableModal from "hooks/useAwaitableModal"
import moment from "moment"
import { FC, Fragment, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import SearchMessagesModal from "./modals/Search"
import { DefaultProfileSrc } from "assets/images"

interface props {
    chatId: string;
}

// https://codesandbox.io/p/sandbox/react-chat-auto-scroll-r081ez?file=%2Fsrc%2FApp2.js%3A66%2C52-67%2C35
const useScroll = (scrollRef: RefObject<HTMLDivElement>, size: number, deps?: any) => {
  const [lastHeight, setLastHeight] = useState<number>(0)

  useEffect(() => {
    if (!scrollRef.current) return
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

    /* if (scrollTop + clientHeight >= scrollHeight - 100) {
      scrollRef.current.scrollTop = scrollHeight

      return
    } */

    if (!lastHeight) {
      scrollRef.current.scrollTop = scrollHeight
    } else {
      if (scrollTop === 0) {
        const diff = scrollHeight - lastHeight
        scrollRef.current.scrollTop = diff
      }
    }
  }, [size, lastHeight, scrollRef, deps])

  useEffect(() => {
    setLastHeight(scrollRef.current?.scrollHeight || 0)
  }, [scrollRef])

  const prepareLoad = useCallback(() => {
    if (!scrollRef.current || !size) return
    const { scrollHeight } = scrollRef.current
    setLastHeight(scrollHeight)
  }, [scrollRef, size])

  return { prepareLoad }
}

const Chat: FC<props> = ({ chatId }) => {
  const user = useAuthContext()
  const isAccountant = useUser().type === "accountant"
  const me = user?.uid!
  const { threads } = useChats()
  const hasUnread = !!threads.find(thread => thread.id === chatId)?.unread
  const { chat, setSearchBeginning, messages: unsortedMessages, concept,
    setConcept, sendConcept, sending, loadNext, loadingNextAfter, loadingNextBefore, loading } = useChat(chatId)
  const messages = unsortedMessages?.slice(0).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
  const fileInput = useRef<HTMLInputElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrolledTo = useRef<string | null>(null)

  const { prepareLoad } = useScroll(scrollRef, messages?.length || 0)

  const [search, SearchMessagesComponent] = useAwaitableModal(SearchMessagesModal, {})

  const [searchedMessage, setSearchedMessage] = useState<string>()

  const onSearch = () => {
    search().then(res => {
      if (!res) return
      prepareLoad()
      if (messages?.find(message => message.id === res.selected?.id)) return setSearchedMessage(res.selected?.id)
      setSearchBeginning(res.selected!)
      setSearchedMessage(res.selected!.id)
    }).catch(() => null)
  }

  const otherPerson = chat?.members?.find(id => id !== me)
  const lastActiveAt = chat?.lastSeen?.[otherPerson!]

  const isOnline = lastActiveAt ? new Date(lastActiveAt).getTime() > Date.now() - 5 * 60 * 1000 : undefined

  useEffect(() => {
    if (!scrollRef.current) return

    const isScrollTop = () => {
      if (!scrollRef.current) return false

      return scrollRef.current.scrollTop < 50
    }

    const isScrollBottom = () => {
      const e = scrollRef.current
      if (!e) return false

      return e.scrollHeight - e.scrollTop - e.clientHeight < 50
    }

    function handleChatScroll() {
      prepareLoad()
      if (isScrollTop()) {
        loadNext("before")
      }
      if (isScrollBottom()) {
        loadNext("after")
      }
    }

    handleChatScroll()

    window.addEventListener("scroll", handleChatScroll, true)

    return () => window.removeEventListener("scroll", handleChatScroll, true)
  }, [loadNext, prepareLoad, scrollRef])

  const navigate = useNavigate()

  useEffect(() => {
    if (hasUnread) {
      prepareLoad()
      loadNext("after", true)
    }
  }, [hasUnread, loadNext, prepareLoad])

  const goToProfile = () => {
    const otherUser = chat?.members.find(id => id !== me)
    if (!otherUser) return
    if (isAccountant) {
      navigate("/recruiter/" + otherUser)
    } else {
      navigate("/accountant/" + otherUser)
    }
  }

  const removeFile = () => {
    setConcept(old => ({ ...old, file: undefined }))
    fileInput.current?.value && (fileInput.current.value = "")
    fileInput.current?.files && (fileInput.current.files = null)
  }

  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendConcept(chatId)

    return false
  }

  const downloadFile = async(file: string, name: string) => {
    const fileRef = ref(firebase.storage, file)
    const url = await getDownloadURL(fileRef)
    const a = document.createElement("a")
    a.href = url
    a.target = "_blank"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  if (!chat || chat.id !== chatId || messages?.some(m => m.chatId !== chatId)) return (
    <div className="flex grow flex-col overflow-hidden h-full items-center justify-center">
      <Loader />
    </div>
  )

  return (
    <div className="flex grow flex-col overflow-hidden h-full">
      {SearchMessagesComponent}
      <div className="flex flex-col grow overflow-hidden">
        <div className="flex items-center gap-4 px-8 pb-4">
          <div className="flex items-center gap-4 group cursor-pointer grow" onClick={goToProfile}>
            <img src={chat?.otherProfiles[0].image || DefaultProfileSrc} alt="Profile" className="w-14 h-14 rounded-full object-cover" />
            <div className="flex flex-col gap-1">
              <span className="font-medium text-lg text-primary-blue group-hover:underline">{chat?.otherProfiles[0].name}</span>
              <span className="text-sm text-text-secondary">
                {
                  isOnline
                    ? "Online"
                    : lastActiveAt ? `Last seen ${moment(new Date(lastActiveAt)).locale("en-US").fromNow()}` : ""
                }
              </span>
            </div>
          </div>
          <button onClick={onSearch} className="btn-secondary group !p-2 border hover:border-primary-purple">
            <SearchIcon className="w-6 h-6 group-hover:fill-primary-purple transition-colors" />
          </button>
        </div>
        <div
          ref={scrollRef}
          className="grow h-full overflow-y-auto border-y-[0.3px] border-border-section"
        >
          <div
            className="flex flex-col justify-end min-h-full gap-4 items-center w-full px-8 py-4 relative"
          >
            {loadingNextBefore && (
              <div className="flex justify-center items-center w-full py-5 mt-9 top-0 absolute">
                <Loader />
              </div>
            )}
            {
              !messages && (
                <div className="flex justify-center items-center h-full">
                  <Loader />
                </div>
              )
            }
            {
              messages?.length === 0 && (
                <div className="flex justify-center items-center h-full">
                  <span className="text-lg text-text-secondary">No messages yet</span>
                </div>
              )
            }
            {messages?.map((message, i) => {
              const isMe = message.author === me
              const authorName = isMe ? "You" : chat?.otherProfiles[0].name
              const previousMessage = messages[i - 1]
              const isToday = new Date(message.createdAt).getDate() === new Date().getDate()
              const isDifferentDay = previousMessage && (
                new Date(message.createdAt).getDate() !== new Date(previousMessage.createdAt).getDate()
              )
              const showDate = !previousMessage || isDifferentDay
              const hasFile = !!message.file
              const fileName = hasFile ? message.file!.name : undefined

              return (
                <Fragment key={message.id}>
                  {showDate && (
                    <span
                      className="text-xs text-text-secondary text-center bg-[#F5F8FB] rounded-full px-2.5 py-1 w-max align-self-center mb-4"
                    >
                      {isToday ? "Today" : new Date(message.createdAt).toDateString()}
                    </span>
                  )}
                  <div
                    ref={el => {
                      if (message.id === searchedMessage) {
                        if (el && scrolledTo.current !== message.id) {
                          el.scrollIntoView({ behavior: "auto", block: "end" })
                          scrolledTo.current = message.id
                        }
                      }
                    }}
                    className={[
                      "flex flex-col gap-2 group",
                      !isMe ? "items-start self-start " : "items-end self-end",
                    ].asClass}
                    style={{
                      maxWidth: "calc(100% - 4rem)",
                    }}
                  >
                    {!isMe && <span className="text-sm text-primary-blue font-medium">{authorName}</span>}
                    <span
                      className="text-base py-3 px-5 rounded-2.5 relative z-10 break-words"
                      style={{
                        background: isMe ? "linear-gradient(316.04deg, #383BF8 3.82%, #6769FF 89.78%)" : "#F3F4F6",
                        borderTopLeftRadius: isMe ? undefined : "0",
                        borderBottomRightRadius: isMe ? "0" : undefined,
                        color: isMe ? "#fff" : "#66628D",
                        maxWidth: "100%",
                      }}
                    >
                      {message.content}
                      {hasFile && (
                        <>
                          {!!message.content.trim() && <br />}
                          <a
                            onClick={() => downloadFile(message.file?.ref!, fileName!)}
                            href="#"
                            className="hover:underline cursor-pointer bg-slate-200 text-primary-blue rounded-sm px-1"
                          >
                            {fileName}
                          </a>
                        </>
                      )}
                    </span>
                    <span
                      className="text-sm text-text-secondary
                    transition-all
                     group-hover:translate-y-0 -translate-y-full opacity-0 group-hover:opacity-100"
                    >
                      {new Date(message.createdAt).toLocaleTimeString(
                        "en-US",
                        { hour: "numeric", minute: "numeric", hour12: true },
                      ).toLowerCase().replace(" ", "")}
                    </span>
                  </div>
                </Fragment>
              )
            })}
            {loadingNextAfter && (
              <div className="flex justify-center items-center w-full py-5 absolute bottom-0">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="flex items-start gap-2.5 pt-4 px-8" style={{ pointerEvents: sending ? "none": "auto" }}>
        <div className="flex flex-col grow ">

          <div className="relative grow">
            <input
              type="text"
              value={concept.content}
              disabled={sending}
              onChange={e => setConcept(old => ({ ...old, content: e.target.value }))}
              placeholder="Type something here..."
              className="w-full !pl-6 !pr-4 rounded-full !border !py-3.5 !border-[#DFE4EA] bg-[#F3F4F6] !outline-none"
            />
            <input
              id="file"
              className="hidden"
              ref={fileInput}
              type="file"
              onChange={e => setConcept(old => ({ ...old, file: e.target.files?.[0] }))}
            />
            <label htmlFor="file" className="absolute right-1 top-1/2 transform -translate-y-1/2">
              <PaperClipIcon
                className="w-10 h-10 text-primary-blue cursor-pointer hover:bg-slate-200 rounded-full transition-colors p-2"
              />
            </label>
          </div>
          {
            (concept.file && (
              <div className="flex items-center gap-2.5 mt-2 p-2 rounded-lg border border-border-section w-max">
                <span className="text-sm text-text-secondary">{concept.file.name}</span>
                <XIcon
                  onClick={removeFile}
                  className="w-4 h-4 text-text-secondary cursor-pointer hover:bg-slate-200 rounded-full transition-colors p-0.5"
                />
              </div>
            ))
          }
        </div>
        <button type="submit" className="btn-primary">
          <SendIcon className="w-6 h-6" />
        </button>
      </form>
    </div>
  )
}

export default Chat
