import { IAccountant } from "api/types"
import ProgressBar from "components/ProgressBar"
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react"
import Section from "./Section"
import accountantApi from "api/accountant"
import { availableCertifications, availableReviews } from "constants/certifications"
import Certification from "./Certification"
import LinkedIn from "./LinkedIn"
import Review from "./Review"
import useAwaitableModal from "hooks/useAwaitableModal"
import { maybeNotSetupRef } from "routes/private"
import CoursePicker from "../../modals/CoursePicker"
import EditPassword from "../../modals/EditPassword"
import EditProfile from "../../modals/EditProfile"
import { handleError } from "helpers/errors"
import SubscribeToGym from "../../modals/SubscribeToGym"
import Tooltip from "components/Tooltip"
import Toggle from "components/Toggle"
import { PenIcon } from "assets/icons"
import { DefaultProfileSrc } from "assets/images"
import recruiterApi from "api/recruiter"
import api from "api"
import { useNavigate } from "react-router"
import ChangeableProfilePicture from "./ChangeableProfilePicture.tsx"
import useAuthContext from "contexts/Auth/useAuthContext"

interface props {
    user: IAccountant & {watched?: boolean, contacted?: string | null}
    setUser?: Dispatch<SetStateAction<IAccountant & {watched?: boolean, contacted?: string | null}>>
    notRecuiter?: boolean
}

const editableProfileFields = ["name", "bio", "state", "city", "employmentType", "zip", "softwareExperience", "linkedIn"] as const

const capitalize = (s?: string) => s ? s.charAt(0).toUpperCase() + s.slice(1) : undefined

const ProfileCard: FC<props> = ({ user, setUser, notRecuiter = false }) => {
  const auth = useAuthContext()
  const [updating, setUpdating] = useState(false)
  const [editProfile, EditProfileModal] = useAwaitableModal(EditProfile, {} as any)
  const [editPassword, EditPasswordModal] = useAwaitableModal(EditPassword, {} as any)
  const [showCoursePicker, CoursePickerModal] = useAwaitableModal(CoursePicker, {} as any)
  const [subscribe, SubscribeToGymModal] = useAwaitableModal(SubscribeToGym, {})
  const [openingGym, setOpeningGym] = useState(false)
  const [addingToWatchlist, setAddingToWatchlist] = useState(false)
  const [contacting, setContacting] = useState(false)
  const navigate = useNavigate()
  const canHaveEmployment = !user.enterprise

  const onAddToWatchlist = () => {
    if (contacting) return
    if (addingToWatchlist) return
    if (notRecuiter) return
    setAddingToWatchlist(true)
    recruiterApi.addCandidate(user.id).then(() => {
      navigate("/candidates")
    }).finally(() => setAddingToWatchlist(false))
  }

  const onContact = () => {
    if (user.contacted) return navigate("/chat/"+user.contacted)
    if (contacting) return
    if (addingToWatchlist) return
    if (notRecuiter) return
    setContacting(true)
    api.createContact(user.id).then((chatId: string) => {
      navigate("/chat/"+chatId)
    }).finally(() => setContacting(false))
  }

  const [watchlistCount, setWatchlistCount] = useState<number>()


  const userTags = Object.values(user?.xpData?.userTags || {})
  const isSubscribedToGym = Boolean(user.xpData.courses[422860]) && !userTags.includes("Gym Suspended")
  const editable = !!setUser

  const withinShare = !auth
  const embedded = withinShare && window.parent !== window

  const onOutsideContact = () => {
    const sp = new URLSearchParams()
    sp.append("redirect", `/accountant/${user.id}`)
    const url = `/auth/signup?${sp.toString()}`
    if (embedded) {
      window.open(url, "_blank")
    } else {
      window.open(url, "_self")
    }
  }

  useEffect(() => {
    if (!editable) return
    accountantApi.getCandidateListCount().then(setWatchlistCount)
  }, [editable])

  const onVisibilityToggle = () => {
    if (!editable) return
    if (!isSubscribedToGym) return subscribe().catch(() => {})
    if (updating) return
    if (user.enterprise) return
    setUpdating(true)
    if (!user.visibleToRecruiters) {
      if (!user.location) {
        handleError("Please set your location in your profile to be visible to recruiters")

        return
      }
    }
    accountantApi
      .setVisibility(!user.visibleToRecruiters)
      .then(() => {
        maybeNotSetupRef.current?.setUser?.({ ...user, visibleToRecruiters: !user.visibleToRecruiters })
      })
      .finally(() => setUpdating(false))
  }

  const onChangeCertification = (name: string, certification: string | null) => {
    if (!editable) return
    if (updating) return
    setUpdating(true)
    accountantApi.updateCertification(name, certification).then(updated => {
      setUser?.({ ...user, certifications: { ...updated } })
    }).finally(() => {
      setUpdating(false)
    })
  }

  const onChangeReview = (name: string, url: string) => {
    if (!editable) return
    if (updating) return
    setUpdating(true)
    const updated = { ...user.reviews, [name]: url }
    accountantApi.updateReviews({ reviews: updated }).then(updated => {
      setUser?.({ ...user, reviews: { ...updated } })
    }).finally(() => {
      setUpdating(false)
    })
  }

  const onUpdateProfileField = (field: typeof editableProfileFields[number]) => (value: string) => {
    if (!editable) return
    if (updating) return
    setUpdating(true)
    const { name, bio, state, city, employmentType, zip, softwareExperience, linkedIn } = user
    const updated = { name, bio, state, city, employmentType, zip, softwareExperience, linkedIn, [field]: value }
    accountantApi.updateProfile(updated).then(updated => {
      setUser?.({ ...user, ...updated })
    }).finally(() => {
      setUpdating(false)
    })
  }

  const onEdit = () => {
    if (!editable) return
    if (updating) return
    setUpdating(true)
    editProfile({
      name: user.name,
      location: user.location,
      city: user.city,
      state: user.state,
      zip: user.zip,
      employmentType: user.employmentType,
      bio: user.bio,
      softwareExperience: user.softwareExperience,
      linkedIn: user.linkedIn,
    })
      .then(edited => {
        if (edited) {
          maybeNotSetupRef.current?.setUser?.({ ...user, ...edited })
        }
      })
      .catch(() => {})
      .finally(() => {
        setUpdating(false)
      })
  }

  const onMagic = () => {
    if (openingGym) return
    setOpeningGym(true)
    accountantApi.openGym(user.magic).then(({ token, courses }) => {
      showCoursePicker({ courses, token }).catch(() => {})
    }).finally(() => setOpeningGym(false))
  }

  const onEditPassword = () => {
    editPassword({})
      .then(() => {})
      .catch(() => {})
  }

  const [city, state] = user.location?.split(", ") || []

  const [progress, missing] = useMemo(() => {
    const nameFilled = user.name ? 1 : 0
    const locationFilled = user.location ? 1 : 0
    const bioFilled = user.bio ? 1 : 0
    const employmentTypeFilled = user.employmentType ? 1 : 0
    const softwareExperienceFilled = Object.keys(user.softwareExperience).length ? 1 : 0
    const certifications = Object.keys(user.certifications).length
    const imageFilled = user.image ? 1 : 0
    const reviewsFilled = Object.keys(user.reviews || {}).length
    const linkedInFilled = user.linkedIn ? 1 : 0
    const sum = nameFilled
    + locationFilled
    + bioFilled + employmentTypeFilled + softwareExperienceFilled + certifications + imageFilled + reviewsFilled + linkedInFilled
    const total = 7 + availableCertifications.length + availableReviews.length

    const missing = [
      !nameFilled && "Add your name",
      !locationFilled && "Add your location",
      !bioFilled && "Add a bio",
      !employmentTypeFilled && "Add your employment type",
      !softwareExperienceFilled && "Add your software experience",
      !imageFilled && "Add a profile picture",
      !linkedInFilled && "Add your LinkedIn",
      ...availableCertifications.map(name => !user.certifications[name] && `Add ${name}`),
      ...availableReviews.map(name => !user.reviews?.[name] && `Add ${name}`),
    ].filter(Boolean)

    return [sum / total, missing as string[]]
  }, [user])

  const noReviews = Object.keys(user.reviews || {}).length === 0
  const noCertifications = Object.keys(user.certifications).length === 0

  return (
    <div className="flex flex-col rounded-2.5 bg-white p-3 w-[16.875rem] shrink-0 gap-2.5 h-max sticky top-0 drop-shadow-md">
      {
        editable && (
          <>
            {EditProfileModal}
            {EditPasswordModal}
            {CoursePickerModal}
            {SubscribeToGymModal}
          </>
        )
      }
      {editable && !user.enterprise && (
        <div className="flex justify-end">
          <div className="flex items-center gap-8 w-full">
            <span
              className="text-xs text-slate-500 grow text-left"
              style={user.visibleToRecruiters ? undefined : { opacity: 0, pointerEvents: "none" }}
            >
              You are on<br />
              <span className="text-xs text-indigo-600 font-medium" onClick={() => {}}>
                {watchlistCount === undefined ? "..." : watchlistCount} Watchlist{watchlistCount === 1 ? "" : "s"}
              </span>
            </span>
            <Tooltip
              position="bottom"
              text={isSubscribedToGym ? "" : "You must be an active member of the QBO Gym to turn this on. Click to purchase"}
              className="text-center"
            >
              <div
                className={["flex items-center gap-2", updating ? "cursor-default grayscale" : "cursor-pointer"].asClass}
                onClick={onVisibilityToggle}
              >
                <span className="text-xs text-text-secondary select-none w-max font-bold">Open to Work</span>
                <Toggle checked={Boolean(user.visibleToRecruiters)} />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="bg-primary-section flex flex-col p-3 rounded-2.5">
        <div className="flex gap-3">
          {editable
            ? <ChangeableProfilePicture src={user.image} />
            : (
              <img
                src={user.image || DefaultProfileSrc}
                alt="profile"
                className="w-[3.25rem] h-[3.25rem] object-cover rounded-full drop-shadow-md"
              />
            )}

          <div className="flex flex-col justify-center">
            <span className="text-[1.125rem] text-primary-blue font-semibold leading-[110%]">
              {user.name}
            </span>
          </div>
        </div>
        {(editable || !notRecuiter || withinShare) && <div className="w-full h-[0.3px] bg-border-section mt-2" />}
        {editable && (
          <Tooltip position="bottom-start" className="whitespace-pre-line leading-[200%]" text={missing.join("\n") || "You're all set!"}>
            <div className="pt-3 flex flex-col cursor-default">
              <div className="flex gap-4 justify-between items-center font-bold text-sm mb-3">
                <span className="text-primary-purple cursor-default">
                  Complete your profile
                </span>
                <span className="text-text-secondary">
                  {Math.ceil(progress * 100)}%
                </span>
              </div>
              <ProgressBar p={progress} color="#5D5FEF" />
            </div>
          </Tooltip>
        )}
        {editable ? (
          <>
            <button
              disabled={openingGym}
              onClick={onMagic}
              className="btn-primary mt-5"
            >
              Go To My Courses
            </button>
            <button
              onClick={onEditPassword}
              className="btn-secondary mt-3 !bg-white"
            >
              Change Password
            </button>
          </>
        ) : !notRecuiter ? (
          <>
            <button
              onClick={onContact}
              disabled={contacting || addingToWatchlist}
              className="btn-primary mt-5"
            >
              {user.contacted ? "Go to Chat" : "Contact Me"}
            </button>
            {!user.watched && !user.enterprise && (
              <button
                onClick={onAddToWatchlist}
                disabled={contacting || addingToWatchlist}
                className="btn-secondary mt-3 !bg-white"
              >
                Add to Watchlist
              </button>
            )}
          </>
        ) : withinShare ? (
          <button
            onClick={onOutsideContact}
            className="btn-primary mt-5"
          >
            Contact Me
          </button>
        ) : null}
      </div>
      <LinkedIn user={user} change={editable ? onUpdateProfileField("linkedIn") : undefined} disabled={updating} />
      <Section header="Certifications" initialExpanded>
        {!editable && noCertifications ? (
          <span className="text-sm font-normal text-slate-500 w-full py-4 px-3">
            No certifications added
          </span>
        ) : (
          <div className="flex flex-col gap-2 p-3 pt-0">
            {availableCertifications.map(name => (
              <Certification key={name} name={name} user={user} change={editable ? onChangeCertification : undefined} disabled={updating} />
            ))}
          </div>
        )}
      </Section>
      <Section header="Reviews">
        <div className="flex flex-col gap-2 p-3 pt-0">
          {!editable && noReviews ? (
            <span className="text-sm font-normal text-slate-500 w-full py-1">
              No reviews yet
            </span>
          ) : (availableReviews.map(name => (
            <Review key={name} name={name} user={user} change={editable ? onChangeReview : undefined} disabled={updating} />
          )))}
        </div>
      </Section>
      <Section
        header={
          <div className="flex items-center justify-between grow">
            <span>
              Basic Info
            </span>
            {editable && (
              <button
                onClick={e => {
                  e.stopPropagation()
                  onEdit()
                }}
                disabled={updating}
                className="text-primary-purple text-base rounded-full hover:bg-[#5D5FEF26]
           transition-colors p-1.5 -mr-1.5 w-6 h-6 flex items-center justify-center"
              >
                <PenIcon />
              </button>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-2 p-3 pt-0">
          {editable && (
            <div className="flex flex-col gap-0.5">
              <span className="text-sm text-[#4848498A]">
                Email
              </span>
              <input value={user.email} readOnly className="text-sm font-medium text-[#484849] bg-transparent outline-none" />
            </div>
          )}
          {canHaveEmployment && (
            <>
              <div className="w-full h-px bg-[#151D4814]" />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm text-[#4848498A]">
                  Employment Type
                </span>
                <span className="text-sm font-medium text-[#484849]">
                  {user.employmentType || "---"}
                </span>
              </div>
            </>
          )}
          <div className="w-full h-px bg-[#151D4814]" />
          <div className="flex flex-col gap-0.5">
            <span className="text-sm text-[#4848498A]">
              Location
            </span>
            <span className="text-sm font-medium text-[#484849]">
              {[capitalize(city), state?.toUpperCase()].filter(Boolean).join(", ") || "---"}
            </span>
          </div>
          <div className="w-full h-px bg-[#151D4814]" />
          <div className="flex flex-col gap-0.5">
            <span className="text-sm text-[#4848498A]">
              Software Knowledge
            </span>
            <pre className="text-sm font-medium text-[#484849] font-sans">
              {Object.keys(user.softwareExperience).join("\n") || "---"}
            </pre>
          </div>
          <div className="w-full h-px bg-[#151D4814]" />
          <div className="flex flex-col gap-0.5">
            <span className="text-sm text-[#4848498A]">
              About {editable ? "Me" : "Accountant"}
            </span>
            <pre className="text-sm font-medium text-[#484849] font-sans whitespace-pre-line">
              {user.bio || "---"}
            </pre>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default ProfileCard
