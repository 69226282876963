import { IAccountant } from "api/types"
import { CertificationIcon, PenIcon } from "assets/icons"
import { availableCertifications } from "constants/certifications"
import useTempFile from "hooks/useTempFile"
import { FC, useEffect, useState } from "react"

interface props {
    name: typeof availableCertifications[number]
    disabled?: boolean
    user: IAccountant
    change?: (name: string, certification: string | null) => void
}

const Certification: FC<props> = ({ name, user, disabled, change }) => {
  const editable = !!change

  const { setFile, progress } = useTempFile(`/users/${user?.id}/certifications/${name}.pdf`)

  const [updateStatus, setUpdateStatus] = useState<null | "uploading" | "saving">(null)

  useEffect(() => {
    if (updateStatus === "uploading" && progress === 1) {
      change?.(name, `/users/${user?.id}/certifications/${name}.pdf`)
      setUpdateStatus("saving")
    }
  }, [change, updateStatus, progress, name, user?.id])

  useEffect(() => {
    if (!disabled) {
      setUpdateStatus(null)
    }
  }, [disabled])

  const present = Object.keys(user.certifications)?.includes(name)

  const url = user.certifications?.[name]

  const onDownload = async() => {
    if (!url) return
    window.open(url + `?nonce=${Date.now()}`, "_blank")
  }

  if (!present && !editable) return null

  if (!editable) {
    return (
      <div
        className={[
          "flex cursor-pointer transition-colors",
          "bg-primary-section-dark hover:bg-[#5D5FEF08]",
          "rounded-2.5 py-2 px-3 items-center justify-center gap-2.5 border border-[#5D5FEF26]",
        ].asClass}
        onClick={onDownload}
      >
        {present && <CertificationIcon className="w-6 h-6" />}
        <span
          className={[
            "text-xs font-normal text-primary-purple w-max truncate grow",
            present ? "text-primary-purple" : " text-text-purple",
          ].asClass}
        >
          {name}
        </span>
      </div>
    )
  }

  return (
    <div
      className={[
        "flex cursor-pointer transition-colors",
        present ? "bg-primary-section-dark [&:hover:not(:has(label:hover))]:bg-[#5D5FEF08]"
          : "border-dashed bg-[#5D5FEF08] [&:hover:not(:has(label:hover))]:bg-primary-section-dark",
        "rounded-2.5 py-2 px-3 items-center justify-center gap-2.5 border border-[#5D5FEF26]",
        (disabled || !!updateStatus) && "pointer-events-none",
        (!!updateStatus) && "animate-pulse",
      ].asClass}
      onClick={present ? () => onDownload() : e => (e.target as HTMLElement).querySelector("input")?.click()}
    >
      <input
        id={name}
        type="file"
        className="hidden"
        accept="application/pdf"
        disabled={disabled || !!updateStatus}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          const file = e.target.files?.[0]
          if (!file) return
          setUpdateStatus("uploading")
          setFile(file)
        }}
      />
      {present && <CertificationIcon className="w-6 h-6" />}
      <span
        className={[
          "text-xs font-normal text-primary-purple w-max truncate grow",
          present ? "text-primary-purple" : " text-text-purple",
        ].asClass}
      >
        {updateStatus === "uploading" ? "Uploading..." : updateStatus === "saving" ? "Saving..." : `${present ? "" : "+ "}${name}`}
      </span>
      <label
        onClick={e => e.stopPropagation()}
        htmlFor={name}
        className="p-1 rounded-full hover:bg-[#5D5FEF26] transition-colors cursor-pointer"
      >
        <PenIcon className="w-4 h-4" />
      </label>
    </div>
  )
}

export default Certification
