import useChats from "contexts/Chat/useChats"
import { FC, useMemo } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router"
import Chat from "./Chat"
import useAuthContext from "contexts/Auth/useAuthContext"
import ShortProfile from "./ShortProfile"
import useHideSupportChat from "hooks/useHideSupportChat"
import { DefaultProfileSrc } from "assets/images"

export const ChatPage: FC = () => {
  const auth = useAuthContext()!
  const chatId = useParams().chatId!
  const { threads } = useChats()
  const otherMembers = useMemo(
    () => threads.find(thread => thread.id === chatId)?.members.filter(id => id !== auth.uid)
    , [chatId, threads, auth.uid],
  )

  return (
    <>
      <Chat chatId={chatId} />
      {otherMembers?.length === 1 && (
        <ShortProfile user={otherMembers[0]} />
      )}
    </>
  )
}

const ChatsPage: FC = () => {
  useHideSupportChat()
  const { threads, usersLastActiveAt } = useChats()
  const auth = useAuthContext()

  const chatId = useLocation().pathname.split("/")[2]
  const navigate = useNavigate()

  return (
    <div className="w-full h-full overflow-hidden pt-5 flex flex-col">
      <div className="flex items-center pb-5 gap-2 px-5">
        <span className="font-semibold text-2xl text-primary-blue">Chat</span>
      </div>
      <div className="grow w-full flex border-t-[0.3px] border-t-[#D3E0EB] pt-4 relative overflow-hidden pb-5 px-5">
        <div
          className="w-[20rem] shrink-0 flex flex-col overflow-y-auto
        rounded-lg drop-shadow-xl bg-white"
        >
          {threads.map(thread => {
            const { name, image } = thread.otherProfiles[0]
            const notMe = thread.members.find(id => id !== auth?.uid)!
            const lastMessage = thread.latestMessage?.content
            const hasUnread = thread.unread > 0
            const isLastFile = !!thread.latestMessage?.file
            const lastMessageText = isLastFile ? "File" : lastMessage || "Start a conversation"
            const isActive = usersLastActiveAt[notMe]
              ? new Date(usersLastActiveAt[notMe]).getTime() > Date.now() - 5 * 60 * 1000
              : undefined

            return (
              <div
                key={thread.id}
                onClick={() => navigate(`/chat/${thread.id}`)}
                className={[
                  "flex items-center hover:bg-[#EAEAFD]  border-b-[0.3px] border-b-border-section",
                  "gap-3.5 p-4 transition-colors cursor-pointer",
                  chatId === thread.id && "bg-[#EAEAFDAA]",
                  hasUnread && "font-semibold",
                ].asClass}
              >
                <div className="relative w-12 h-12 shrink-0">
                  <img src={image || DefaultProfileSrc} alt={name} className="w-12 h-12 rounded-full object-cover" />
                  {isActive !== undefined && (
                    <div
                      className="absolute w-3 h-3 rounded-full bottom-0 right-0 border-2 border-white"
                      style={{
                        backgroundColor: isActive ? "#3EEA3E" : "#EA3E3E",
                      }}
                    />
                  )}
                </div>
                <div className="flex flex-col grow">
                  <span className="font-medium text-sm text-primary-blue truncate">{name}</span>
                  <span className="text-xs text-text-secondary truncate">{lastMessageText}</span>
                </div>
                {hasUnread && (
                  <span
                    className="w-5 h-5 rounded-full bg-primary-purple text-xs flex
                 items-center justify-center text-center text-white font-bold"
                  >
                    {thread.unread}
                  </span>
                )}
              </div>
            )
          })}
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default ChatsPage
