import { Logo } from "assets"
import { ChatIcon, LogoutIcon } from "assets/icons"
import ProfileImage from "components/ProfileImage"
import Scrollbar from "components/Scrollbar"
import Tooltip from "components/Tooltip"
import firebase from "config/firebase"
import ChatProvider from "contexts/Chat"
import useChats from "contexts/Chat/useChats"
import useUser from "contexts/User/useUser"
import { signOut } from "firebase/auth"
import { FC, Fragment, FunctionComponent, useState } from "react"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router"

interface props {
  routes: {
    path: string
    onClick?: () => void
    name: string
    icon: FunctionComponent<React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
}>
  }[]
}

const UnreadBubble: FC = () => {
  const { threads } = useChats()
  const hasUnread = threads?.some(thread => !!thread.unread)

  if (!hasUnread) return null

  return (
    <div className="absolute w-3 h-3 rounded-full top-0 right-0 bg-gradient-to-tl from-red-600 to-red-400" />
  )
}

const CommonAppLayout: FC<props> = ({ routes }) => {
  const user = useUser()
  const [signingOut, setSigningOut] = useState(false)
  const navigate = useNavigate()
  const pathname = useLocation().pathname

  const onLogout = () => {
    if (signingOut) return
    setSigningOut(true)
    signOut(firebase.auth).finally(() => setSigningOut(false))
  }

  if (pathname === "/") return <Navigate to={routes[0].path} replace />

  return (
    <ChatProvider>
      <div className="w-full h-full bg-white flex p-2">
        <div className="h-full rounded-3xl bg-primary-blue flex-cool gap-2.5 items-center py-4">
          <Logo className="w-[2.75rem] h-[2.75rem] p-1.5 rounded-2xl bg-primary-purple fill-white shrink-0" />
          <div className="grow flex-cool gap-4 w-full px-4 items-center overflow-y-auto relative mt-4">
            {routes.map((route, i) => (
              <Fragment key={route.path}>
                {!!i && (<div className="w-8 h-px bg-border-divide" />)}
                <Tooltip position="right" text={route.name} className="bg-slate-800 z-20">
                  <route.icon
                    onClick={route.onClick || (() => navigate(route.path))}
                    className={[
                      "w-[2.75rem] h-[2.75rem] p-2.5 rounded-full hover:bg-[rgba(255,255,255,0.12)] cursor-pointer transition-color",
                      pathname.startsWith(route.path) && "bg-[rgba(255,255,255,0.12)]",
                    ].asClass}
                  />
                </Tooltip>
              </Fragment>
            ))}
            <div className="w-8 h-px bg-border-divide" />
            <Tooltip position="right" text="Chat" className="bg-slate-800 z-20">
              <div className="relative">
                <ChatIcon
                  onClick={() => navigate("/chat")}
                  className={[
                    "w-[2.75rem] h-[2.75rem] p-2.5 rounded-full overflow-visible",
                    "hover:bg-[rgba(255,255,255,0.12)] cursor-pointer transition-color",
                    pathname.startsWith("/chat") && "bg-[rgba(255,255,255,0.12)]",
                  ].asClass}
                />
                <UnreadBubble />
              </div>
            </Tooltip>
            <Scrollbar />
          </div>
          <div className="cursor-pointer" onClick={() => navigate("/profile")}>
            <ProfileImage size={44} src={user.image} className="hover:p-0.5 p-0 w-[2.75rem] h-[2.75rem] transition-all" />
          </div>
          <Tooltip position="right" text="Log Out" className="bg-slate-800 z-20">
            <LogoutIcon
              onClick={onLogout}
              className="w-[2.75rem] h-[2.75rem] p-2.5 rounded-full hover:bg-[rgba(255,255,255,0.12)] cursor-pointer transition-color"
            />
          </Tooltip>
        </div>
        <div className="h-full grow w-full overflow-hidden">
          <Outlet />
        </div>
      </div>
    </ChatProvider>
  )
}

export default CommonAppLayout
