import { FC, useEffect, useState } from "react"
import { IAccountant, IUser } from "api/types"
import { useParams, useNavigate, Navigate } from "react-router"
import LoadedAccountantProfilePage from "./Loaded"
import shareApi from "api/share"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"

const canSeeAccountants = (type: IUser["type"]) => type === "admin" || type === "recruiter"

const SharedAccountantProfilePage: FC = () => {
  const [user, setUser] = useState<IAccountant>()
  const navigate = useNavigate()
  const authUser = useMaybeNotSetupUser()
  const shareToken = useParams().shareToken


  useEffect(() => {
    if (!shareToken) return navigate("/", { replace: true })
    shareApi.getSharedAccountantProfile(shareToken).then(u => {
      if (!u) return navigate("/", { replace: true })
      setUser(u)
    })
  }, [shareToken])

  if (authUser.user && user) {
    if (authUser.user.id === user.id) return <Navigate to="/profile" replace />
    if (!canSeeAccountants(authUser.user.type)) return <Navigate to="/" replace />

    return <Navigate to={`/accountant/${user.id}`} replace />
  }

  if (user) return <LoadedAccountantProfilePage user={user} />

  return (
    <div className="w-full h-full overflow-hidden p-5 flex flex-col">
      <div className="flex items-center pb-5 gap-2">
        <span className="font-semibold text-2xl text-primary-blue">Accountant Profile</span>
      </div>
      <div className="grow w-full overflow-y-auto border-t-[0.3px] border-t-[#D3E0EB] pt-4 relative">
        <div
          className="flex grow w-full gap-4 overflow-x-hidden"
        >
          <div className="rounded-2.5 bg-slate-200 p-3 w-[16.875rem] gap-2.5 h-[30rem] sticky top-0 animate-pulse" />
          <div className="grid grid-cols-2 2xl:grid-cols-4 gap-4 grow h-max pb-10">
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2 2xl:col-span-4" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharedAccountantProfilePage
