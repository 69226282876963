import { BuildingIcon, EnvelopeIcon, LockIcon, SubscriptionIcon } from "assets/icons"
import ProfileImage from "components/ProfileImage"
import { FC, useEffect, useState } from "react"
import { IRecruiter } from "api/types"
import api from "api"
import { useNavigate, useParams } from "react-router"
import FullLoader from "components/loaders/FullLoader"

const formatAddress: (address?: any) => string = address => {
  if (!address) return "No Address Given"

  return [address.street1, address.street2, address.city, address.state, address.country].filter(Boolean).join(", ")
}

const RecruiterProfilePage: FC = () => {
  const [user, setUser] = useState<IRecruiter>()
  const id = useParams().id
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return navigate(-1)
    api.getProfile(id).then(u => (u ? setUser(u as IRecruiter) : navigate(-1)))
  }, [id])

  return (
    <div className="w-full h-full">
      <div className="pt-8 px-8 gap-8 flex-cool h-full">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4">
          <span className="font-semibold text-3xl text-slate-900">Recruiter Profile</span>
        </div>
        <div
          className="flex-cool min-h-min
          grow
        bg-white rounded-3xl divide-y
        divide-y-slate-200 overflow-hidden overflow-y-auto"
        >
          {user === undefined ? (
            <FullLoader />
          ) : (
            <>
              <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
                <ProfileImage size={96} src={user.image} />
                <div className="flex-cool gap-1 grow">
                  <span className="text-2xl font-medium text-slate-900">{user.name}</span>
                  <span className="text-slate-500 text-base">{user.recruiterType}</span>
                </div>
              </div>
              <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
                <EnvelopeIcon />
                <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
                  <span className="text-slate-500 text-base grow">Email Address</span>
                  <span className="text-slate-900 text-base grow">{user.email}</span>
                </div>
              </div>
              <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
                <LockIcon />
                <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
                  <span className="text-slate-500 text-base grow">Password</span>
                  <span className="text-slate-900 text-base grow">••••••••••••••••</span>
                </div>
              </div>
              <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
                <BuildingIcon />
                <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
                  <span className="text-slate-500 text-base grow">Company Address</span>
                  <span className="text-slate-900 text-base grow">{formatAddress(user.address)}</span>
                </div>
              </div>
              <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
                <SubscriptionIcon className="stroke-slate-500" />
                <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
                  <span className="text-slate-500 text-base grow">Credit Card</span>
                  <span className="text-slate-900 text-base grow">•••• •••• •••• 1234</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RecruiterProfilePage
