import api from "api"
import { RatingStarIcon, ThankYouCheckIcon } from "assets/icons"
import Modal from "components/Modal"
import ProfileImage from "components/ProfileImage"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"

const Feedback: AwaitableModal<{}, {id: string, name: string, image?: string}> = ({ open, resolve, onCancel, initialData }) => {
  const [feedback, setFeedback] = useState("")
  const [rating, setRating] = useState(5)
  const [submitting, setSubmitting] = useState(false)
  const [sent, setSent] = useState(false)
  const data = initialData || {}

  const onSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    api.leaveFeedback(data.id, feedback, rating).then(() => {
      setSent(true)
    }).finally(() => setSubmitting(false))
    // resolve({ feedback, rating })
  }

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div
        className="bg-white rounded-3xl divide-y divide-y-slate-200 overflow-hidden
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <header className="flex items-center justify-start gap-2.5 p-8">
          <span className="font-semibold text-2xl text-slate-900">Leave Feedback</span>
        </header>
        {!sent ? (
          <>
            <main className="flex-cool p-8 gap-8 items-center overflow-y-auto">
              <ProfileImage src={data.image} size={128} />
              <div className="flex-cool items-center text-center gap-0.5">
                <span className="text-sm text-slate-500">Feedback For</span>
                <span className="text-2xl text-slate-900 font-medium">{data.name}</span>
              </div>
              <div className="flex-cool gap-4 w-full">
                <div className="flex items-center relative max-w-max self-center">
                  <input
                    type="range"
                    className="opacity-0 absolute inset-0 cursor-pointer"
                    min={0}
                    max={5}
                    step={1}
                    onChange={e => setRating(e.currentTarget.valueAsNumber)}
                  />
                  {Array(5).fill(null).map((_, i) => (
                    <RatingStarIcon className={rating >= i + 1 ? undefined : "fill-slate-100"} key={i} />
                  ))}
                </div>
                <textarea
                  placeholder="Describe Your Experience"
                  rows={5}
                  className="w-full"
                  value={feedback}
                  onChange={e => setFeedback(e.currentTarget.value)}
                />
              </div>
            </main>
            <footer className="p-8 flex items-center justify-between gap-2.5">
              <button className="btn-secondary" disabled={submitting} onClick={onCancel}>
                Cancel
              </button>
              <button disabled={submitting} onClick={onSubmit} className="btn-primary">Leave Feedback</button>
            </footer>
          </>
        )
          :(
            <>
              <main className="flex-cool p-8 gap-8 items-center">
                <ThankYouCheckIcon />
                <div className="gap-0.5 flex-cool items-center">
                  <span className="text-3xl font-medium text-slate-900 text-center">Feedback Saved</span>
                  <span className="text-sm text-slate-500 text-center">Thank You For Using Our Service</span>
                </div>
              </main>
              <footer className="p-8 flex items-center justify-center">
                <button onClick={resolve} className="btn-primary">Continue</button>
              </footer>
            </>
          )}
      </div>
    </Modal>
  )
}

export default Feedback
