import ShareAppLayout from "layouts/App/ShareLayout"
import SharedAccountantProfilePage from "pages/share/Accountant"
import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"

const ShareRouter: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Outlet />}>
        <Route index element={<Navigate to="/" />} />
        <Route path=":shareToken" element={<ShareAppLayout />}>
          <Route
            path="accountant"
            element={<SharedAccountantProfilePage />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

export default ShareRouter
