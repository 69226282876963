export const availableCertifications = [
  "QBO ProAdvisor",
  "QBO Advanced ProAdvisor",
  "QBO Payroll ProAdvisor",
] as const

export const availableReviews = [
  "ProAdvisor Reviews",
  "Google Reviews",
]
